<template>
  <div class="p40 vertical centered flex">
    <h2 style="text-align: center;">You have successfully unsubscribed from our mailing list.</h2>
    <a  @click="getAddedBack" style="text-align: center;" class="fs16 mt12 mb12 pointer">Made a mistake? Click here to get added back.</a>
  </div>
</template>

<script>
import st from 'storerestapi'
export default {
  name: 'unsubscribe',
  mounted () {
    this.$req.post(`updateUser?id=${this.$bus.user.id}`, {
      acceptingMassmail: false,
      updater: this.$bus.user.id
    }, {
      headers: {
        Authorization: `Bearer ${this.$bus.token}`
      }
    })
    .then(() => {
      this.$bus.user = {
        ...this.$bus.user,
        acceptingMassmail: false
      }
      st.setVar('user', this.$bus.user)
      this.$bus.notify("Unsubscribed")
    })
    .catch(e => console.log(e))
  },
  methods: {
    getAddedBack: function () {
      this.$req.post(`updateUser?id=${this.$bus.user.id}`, {
        acceptingMassmail: true,
        updater: this.$bus.user.id
      }, {
        headers: {
          Authorization: `Bearer ${this.$bus.token}`
        }
      })
      .then(() => {
        this.$bus.user = {
          ...this.$bus.user,
          acceptingMassmail: true
        }
        st.setVar('user', this.$bus.user)
        this.$bus.notify("Added back to mailing list")
      })
      .catch(e => console.log(e))
    }
  }
}
</script>